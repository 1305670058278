import { Box, Button, useMediaQuery } from "@mui/material";
import { TYPE_HELPER } from "common/User";
import { CATE_PUBLIC, TYPE_NEWS } from "components/Common/Topic";
import { TYPE_ORGANIZATION } from "components/Common/User";
import Langs from "components/Const/Lang";
import cookies from "next-cookies";
import { useRouter } from "next/router";

export default function Custom404() {
  const router = useRouter();
  const matches = useMediaQuery("(min-width:768px)");
  const lang =
    cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  return (
    <Box
      sx={{
        with: "100vw",
        height: "100vh",
        position: "relative",
        backgroundColor: "#fef9f6",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div style={{ margin: "auto", display: "flex", flexDirection: "column" }}>
        <img
          src={"/404.jpeg"}
          alt="img404"
          style={{ width: matches ? "500px" : "100vw", objectFit: "cover" }}
        />
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: "150px", margin: "auto" }}
          onClick={() =>
            router.push({
              pathname: "/",
              query: {
                memberTypes: `[${TYPE_HELPER?.id}, ${TYPE_ORGANIZATION?.id}]`,
                types: `[${CATE_PUBLIC?.id}]`,
                categoryId: TYPE_NEWS?.id,
              },
            })
          }
        >
          {Langs?.tro_ve?.[lang]}
        </Button>
      </div>
    </Box>
  );
}
